<template>
  <div style="width: 100%; height: 100%; padding-bottom: 40px" class="fadeIn">
    <!-- heade -->
    <div style="height: 30%; width: 100%">
      <div class="header">
        <div style="font-size: 16px; text-align: left; width: 300px">
          {{ $route.query.projectName }}
          <van-icon
            name="more-o"
            v-if="options.length && radio == 2"
            @click="showPicker = true"
          />
          <br />
          <van-row style="display: flex; align-items: center">
            <van-col>
              <van-radio-group
                v-model="radio"
                style="display: flex"
                @change="onetrendChange"
              >
                <van-radio :name="1">日查询</van-radio>
                <van-radio :name="2" v-show="options.length">班查询</van-radio>
              </van-radio-group>
            </van-col>
            <van-col>
              <el-date-picker
                style="width: 130px"
                :picker-options="pickerOption"
                v-model="getForm.date"
                type="date"
                @change="(getForm.current = 1), getMaxTime()"
                value-format="yyyy-MM-dd"
                size="mini"
                placeholder="选择日期"
              >
              </el-date-picker>
            </van-col>
          </van-row>
        </div>
        <div
          style="
            cursor: pointer;
            color: rgb(2, 185, 128);
            font-size: 14px;
            line-height: 28px;
            width: 100px;
          "
          @click="$router.go(-1)"
        >
          <i class="el-icon-arrow-left"></i>返回
        </div>
      </div>
      <div class="oeeTitle" style="padding: 0 10px">OEE时序图</div>
      <div style="height: 50%; padding: 5px 10px 0">
        <div id="oeeBody" ref="chart"></div>
      </div>
    </div>
    <!-- main饼图 -->
    <!-- <div class="main" v-if="type == 'C'">
      <div id="allTime"></div>
    </div> -->
    <div style="width: 100%; height: 20%" class="main">
      <div
        style="
          border: 1px solid #dee1e6;
          border-radius: 10px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
          height: 100%;
          width: 100%;
          display: flex;
        "
      >
        <div
          ref="chart"
          id="fourTime"
          class="pieBox"
          style="border-right: 1px dashed #dadce0"
        ></div>
        <div id="fourFrequency" class="pieBox" ref="chart"></div>
        <!-- <div v-else>
          <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            description="暂无数据"
          />
        </div> -->
      </div>
    </div>
    <!-- footer -->
    <div class="oeebody">
      <div class="oeeTitle">OEE时序明细</div>
      <el-table :data="oeeData" style="width: 100%" height="100%">
        <el-table-column
          prop="startTime"
          :width="type ? '80px' : ''"
          label="开始时间"
        >
          <template slot-scope="{ row }">
            <span>{{ row.startTime | filterStart }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" :width="type ? '70px' : ''">
          <template slot-scope="scope">
            <span v-if="scope.row.lampState == 1"
              ><i
                class="el-icon-s-opportunity"
                style="color: #ff0000; font-size: 15px"
              ></i
              >红灯</span
            >
            <span v-else-if="scope.row.lampState == 2"
              ><i
                class="el-icon-s-opportunity"
                style="color: #ffcb38; font-size: 15px"
              ></i
              >黄灯</span
            >
            <span v-else-if="scope.row.lampState == 3"
              ><i
                class="el-icon-s-opportunity"
                style="color: #65c436; font-size: 15px"
              ></i
              >绿灯</span
            >
            <span v-else-if="scope.row.lampState == 0"
              ><i
                class="el-icon-s-opportunity"
                style="color: gray; font-size: 15px"
              ></i
              >灭灯</span
            >
            <span v-else-if="scope.row.lampState == 4"
              ><i
                class="el-icon-s-opportunity"
                style="color: #5470c6; font-size: 15px"
              ></i
              >蓝灯</span
            >
          </template>
        </el-table-column>
        <el-table-column label="运行时长" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.duration | timeFilters }}
          </template>
        </el-table-column>
        <el-table-column label="操作人" prop="fullName" />
        <el-table-column label="原因" v-if="type" width="100">
          <template slot-scope="{ row }">
            <span v-if="row.causeName"
              ><van-icon
                style="margin-right: 2px"
                name="edit"
                size="14px"
                color="#1989fa"
                @click.stop="updateCause(row)"
              />{{ row.causeName }}
            </span>
            <span v-else
              ><van-icon
                style="margin-right: 2px"
                name="edit"
                size="14px"
                color="#1989fa"
                @click.stop="updateCause(row)"
              />{{ row.causeName }}无</span
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="position: fixed; left: 0; bottom: 0; width: 100%">
        <van-pagination
          v-model="getForm.current"
          @change="getOEEData"
          :total-items="getForm.total"
          force-ellipses
        />
      </div>
    </div>
    <van-popup v-model="showPicker" position="bottom" round>
      <van-picker
        show-toolbar
        :columns="options"
        @confirm="shiftChange"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup
      round
      v-model="CausePopup"
      position="bottom"
      :style="{ height: '50%' }"
    >
      <van-picker
        title="原因列表"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  // mixins: [chartMixin],
  data() {
    return {
      showPicker: false,
      radio: 2,
      causePie: "",
      min: "",
      max: "",
      nowShiftId: "",
      currentShift: "",
      options: [],
      frequencyBox: true,
      chartRef: ["chart"],
      AbnormalData: [],
      columns: [],
      CausePopup: false,
      type: this.$route.query.projectType,
      oeeData: [],
      oeeList: [],
      pickerOption: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      form: {
        causeCode: "",
        triColorLampId: "",
        startTime: "",
        projectId: "",
        lampState: "",
      },
      getForm: {
        projectId: this.$route.query.projectId,
        size: 10,
        current: 1,
        total: 0,
        date: "",
      },
      setOee(data, ref, yData, num) {
        let min = this.min;
        let max = this.max;
        function renderItem(params, api) {
          var categoryIndex = api.value(0);
          var start = api.coord([api.value(1), categoryIndex]);
          var end = api.coord([api.value(2), categoryIndex]);
          var height = api.size([0, 1])[1] * num;
          var rectShape = echarts.graphic.clipRectByRect(
            {
              x: start[0],
              y: start[1] - height / 2,
              width: end[0] - start[0],
              height: height,
            },
            {
              x: params.coordSys.x,
              y: params.coordSys.y,
              width: params.coordSys.width,
              height: params.coordSys.height,
            }
          );
          return (
            rectShape && {
              type: "rect", //sector circle polygon rect
              transition: ["shape"],
              shape: rectShape,
              // style: api.style(),
              style: {
                fill: api.visual("color"),
              },
            }
          );
        }
        let option = {
          tooltip: {
            position: function (point, params, dom, rect, size) {
              // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
              // 提示框位置
              var x = 0; // x坐标位置
              var y = 0; // y坐标位置

              // 当前鼠标位置
              var pointX = point[0];
              var pointY = point[1];

              // 外层div大小
              // var viewWidth = size.viewSize[0];
              // var viewHeight = size.viewSize[1];

              // 提示框大小
              var boxWidth = size.contentSize[0];
              var boxHeight = size.contentSize[1];

              // boxWidth > pointX 说明鼠标左边放不下提示框
              if (boxWidth > pointX) {
                x = 5;
              } else {
                // 左边放的下
                x = pointX - boxWidth;
              }

              // boxHeight > pointY 说明鼠标上边放不下提示框
              if (boxHeight > pointY) {
                y = 5;
              } else {
                // 上边放得下
                y = pointY - boxHeight;
              }

              return [x, y];
            },
            formatter: function (params) {
              let date = new Date(params.value[1]);
              let Y = date.getFullYear() + "-";
              let M =
                (date.getMonth() + 1 < 10
                  ? date.getMonth() + 1
                  : date.getMonth() + 1) + "-";
              let D =
                (date.getDate() < 10 ? date.getDate() : date.getDate()) + " ";

              let h =
                (date.getHours() < 10
                  ? "0" + date.getHours()
                  : date.getHours()) + ":";
              let m =
                (date.getMinutes() < 10
                  ? "0" + date.getMinutes()
                  : date.getMinutes()) + ":";
              let s =
                date.getSeconds() < 10
                  ? "0" + date.getSeconds()
                  : date.getSeconds();
              let strDate = Y + M + D + h + m + s;
              strDate = strDate.replace(/-/g, "/");
              let date1 = new Date(params.value[2]);
              let str = params.marker + params.name + ": ";
              if (params.value[3] / 1000 / 3600 >= 1) {
                str += (params.value[3] / 1000 / 3600).toFixed(0) + "时";
              }
              if (((params.value[3] / 1000) % 3600) / 60 >= 1) {
                str +=
                  (((params.value[3] / 1000) % 3600) / 60).toFixed(0) + "分";
              }
              str +=
                ((params.value[3] / 1000) % 60) +
                "秒" +
                `<br/>` +
                params.marker +
                params.name +
                ": " +
                `<br/>` +
                strDate +
                `<br/>` +
                date1.toLocaleString("chinese", { hour12: false });
              if (!params.name) {
                str = "";
              }
              return '<div style="text-align:left">' + str + "</div>";
            },
            // axisPointer: {
            //   type: "cross",
            // },
          },
          dataZoom: [
            {
              type: "inside",
              filterMode: "weakFilter",
              showDataShadow: false,
              top: 0,
              labelFormatter: "",
            },
            {
              type: "inside",
              filterMode: "weakFilter",
            },
          ],
          grid: {
            left: "2%",
            top: "0",
            bottom: "40%",
          },
          xAxis: {
            min,
            max,
            show: true,
            type: "time",
            boundaryGap: false,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
          },
          yAxis: {
            data: yData,
            axisLine: {
              show: false,
            },
          },
          series: [
            {
              data: data,
              type: "custom",
              renderItem: renderItem,
              itemStyle: {
                opacity: 1,
              },
              encode: {
                x: [1, 2],
                y: 0,
              },
            },
          ],
        };
        // var chartDom = this.$refs.oeeBody;
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setPie(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let option = {
          title: {
            text: "颜色时长总计",
            subtext: "Duration statistics",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "75%", //图例距离左的距离
            top: "center",
            data: legendDate,
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "90%",
              center: ["50%", "50%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{d|{d}%} \n {b|{b}:{c}小时}",
                  rich: {
                    d: {
                      color: "#fff",
                      fontSize: 12,
                      lineHeight: 24,
                      height: 24,
                    },
                    b: {
                      color: "#f3eae6",
                      fontSize: 12,
                      lineHeight: 20,
                      align: "left",
                    },
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setfourPie(data, ref) {
        let legendDate = [];
        data = data.filter((item) => {
          if (item.value > 0) {
            legendDate.push({ name: item.name, icon: "circle" });
            return item;
          }
        });
        let option = {
          title: {
            text: "颜色时长总计",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            orient: "vertical",
            left: "65%", //图例距离左的距离
            top: "center",
            data: legendDate,
          },
          series: [
            {
              name: "Access From",
              type: "pie",
              radius: "80%",
              center: ["35%", "56%"],
              data: data,
              label: {
                normal: {
                  color: "#e8e8e8",
                  position: "inner", // 设置标签位置，默认在饼状图外 可选值：'outer' ¦ 'inner（饼状图上）'
                  // formatter: '{a} {b} : {c}个 ({d}%)'   设置标签显示内容 ，默认显示{b}
                  // {a}指series.name  {b}指series.data的name
                  // {c}指series.data的value  {d}%指这一部分占总数的百分比
                  formatter: "{d|{d}%} \n {b|{b}:{c}小时}",
                  rich: {
                    d: {
                      color: "#fff",
                      fontSize: 8,
                      lineHeight: 10,
                      height: 10,
                    },
                    b: {
                      color: "#f3eae6",
                      fontSize: 8,
                      lineHeight: 10,
                      align: "left",
                    },
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      setFrequency(pieData, pieDataT, ref) {
        // let legendDate = [];
        // data = data.filter((item) => {
        //   if (item.value > 0) {
        //     legendDate.push({ name: item.name, icon: "circle" });
        //     return item;
        //   }
        // });
        let option = {
          title: {
            text: "原因明细分析",
            left: "left",
            textStyle: {
              fontSize: 12,
            },
          },
          legend: {
            icon: "circle",
            bottom: "5%",
            type: "scroll",
            orient: "vertical",
            right: "2%",
            // top: "center",
            // data: legendDate,
            textStyle: {
              fontsize: 8,
              padding: [0, 0, 0, -10],
            },
            itemHeight: 10, //修改图形大小
            itemGap: 8, //修改间距
            formatter: function (name) {
              // 获取legend显示内容
              let data1 = pieDataT; //这个是展示的数据
              let total = 0;
              let tarValue = 0;
              let value = 0;
              for (let i = 0, l = data1.length; i < l; i++) {
                total += data1[i].value;
                if (data1[i].name == name) {
                  tarValue = data1[i].value;
                  value = data1[i].value;
                }
              }
              let p = total == 0 ? 0 : ((tarValue / total) * 100).toFixed(1);
              return echarts.format.truncateText(
                name,
                60,
                "14px Microsoft Yahei",
                "…" //如果宽度超过80会出现...
              );
            },
          },
          tooltip: {
            position: [-80, -50],
            // position: function (point, params, dom, rect, size) {
            //   // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            //   // 提示框位置
            //   var x = 0; // x坐标位置
            //   var y = 0; // y坐标位置

            //   // 当前鼠标位置
            //   var pointX = point[0];
            //   var pointY = point[1];

            //   // 外层div大小
            //   // var viewWidth = size.viewSize[0];
            //   // var viewHeight = size.viewSize[1];

            //   // 提示框大小
            //   var boxWidth = size.contentSize[0];
            //   var boxHeight = size.contentSize[1];

            //   // boxWidth > pointX 说明鼠标左边放不下提示框
            //   if (boxWidth > pointX) {
            //     x = 5;
            //   } else {
            //     // 左边放的下
            //     x = pointX - boxWidth;
            //   }

            //   // boxHeight > pointY 说明鼠标上边放不下提示框
            //   if (boxHeight > pointY) {
            //     y = 5;
            //   } else {
            //     // 上边放得下
            //     y = pointY - boxHeight;
            //   }

            //   return [x , y];
            // },
            trigger: "item",
            formatter: function (params) {
              const h = parseInt(params.value / 3600);
              const minute = parseInt((params.value / 60) % 60);
              const second = Math.ceil(params.value % 60);
              const hours = h < 10 ? "0" + h : h;
              const formatSecond = second > 59 ? 59 : second;
              return (
                "<div style='text-align:left'>" +
                '<span style="font-size:12px;font-weigth:700;margin-right:10px;color:#1a73e8">' +
                params.name +
                "</span>" +
                `${hours > 0 ? `${hours}时:` : ""}${
                  minute < 10 ? "0" + minute + "分" : minute + "分"
                }:${
                  formatSecond < 10
                    ? "0" + formatSecond + "秒"
                    : formatSecond + "秒"
                }` +
                "<br/>" +
                '<span style="font-size:12px;font-weigth:700;margin-right:10px">' +
                "时长占比" +
                "</span>" +
                params.percent +
                "%" +
                "</div>"
              );
            },
          },
          series: [
            {
              zlevel: 5,
              name: "原因明细",
              type: "pie",
              center: ["30%", "56%"],
              radius: ["55%", "60%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 14,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: pieDataT,
            },
            {
              zlevel: 0,
              name: "时长占比",
              type: "pie",
              center: ["30%", "56%"],
              radius: ["45%", "70%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 6,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: pieData,
            },
          ],
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
        this.causePie = myChart;
      },
    };
  },
  filters: {
    filterStart(data) {
      return data.split(" ")[1];
    },
    timeFilters(data) {
      let str = "";
      if (data / 3600 >= 1) {
        str = str + (data - (data % 3600)) / 3600 + "小时";
      }
      if ((data % 3600) / 60 >= 1) {
        str = str + ((data % 3600) - (data % 60)) / 60 + "分";
      }
      str = str + (data % 60) + "秒";
      return str;
    },
  },
  methods: {
    shiftChange(val) {
      this.showPicker = false;
      this.currentShift = val.shiftId;
      this.getMaxTime();
    },
    async getShift() {
      await this.$api.triColorLamp
        .getShift(this.$route.query.projectId)
        .then((res) => {
          if (res.code == 200) {
            res.data?.ShiftList?.forEach((item) => {
              item.text = item.shiftName;
            });
            this.options = res.data.ShiftList || [];
            this.currentShift = res.data.shiftId || "";
            this.getoeeList();
            if (this.currentShift) {
              this.radio = 2;
              let nowTime = this.options.filter(
                (item) => item.shiftId == this.currentShift
              )[0];
              this.min = `${this.dayjs(this.getForm.date).format(
                "YYYY-MM-DD"
              )} ${nowTime.startTime}:00`;
              if (Number(nowTime.nextDay) > 0) {
                this.max = `${this.dayjs(this.getForm.date)
                  .add(1, "day")
                  .format("YYYY-MM-DD")} ${nowTime.endTime}:00`;
              } else {
                this.max = `${this.dayjs(this.getForm.date).format(
                  "YYYY-MM-DD"
                )} ${nowTime.endTime}:00`;
              }
            } else {
              this.radio = 1;
              this.min = `${this.dayjs(this.getForm.date).format(
                "YYYY-MM-DD"
              )} 00:00:00`;
              this.max = `${this.dayjs(this.getForm.date)
                .add(1, "day")
                .format("YYYY-MM-DD")} 00:00:00`;
            }
          }
        });
      // await this.$api.setCause
      //   .getProjectCauseList(this.$route.query.projectId)
      //   .then((res) => {
      //     if (res.code == 200) {
      //       for (let key in res.data) {
      //         let obj = {
      //           value: key * 1,
      //           label: res.data[key],
      //         };
      //         this.AbnormalData.push(obj);
      //       }
      //     }
      //   });
    },
    getMaxTime() {
      if (this.radio == 1) {
        this.min = `${this.dayjs(this.getForm.date).format(
          "YYYY-MM-DD"
        )} 00:00:00`;
        this.max = `${this.dayjs(this.getForm.date)
          .add(1, "day")
          .format("YYYY-MM-DD")} 00:00:00`;
      } else {
        let nowTime = this.options.filter(
          (item) => item.shiftId == this.currentShift
        )[0];
        this.min = `${this.dayjs(this.getForm.date).format("YYYY-MM-DD")} ${
          nowTime.startTime
        }:00`;
        if (Number(nowTime.nextDay) > 0) {
          this.max = `${this.dayjs(this.getForm.date)
            .add(1, "day")
            .format("YYYY-MM-DD")} ${nowTime.endTime}:00`;
        } else {
          this.max = `${this.dayjs(this.getForm.date).format("YYYY-MM-DD")} ${
            nowTime.endTime
          }:00`;
        }
      }
      this.getoeeList();
    },
    onetrendChange(val) {
      if (val == 1) {
        this.nowShiftId = this.currentShift;
        this.currentShift = "";
        this.min = `${this.dayjs(this.getForm.date).format(
          "YYYY-MM-DD"
        )} 00:00:00`;
        this.max = `${this.dayjs(this.getForm.date)
          .add(1, "day")
          .format("YYYY-MM-DD")} 00:00:00`;
        this.getoeeList();
      } else {
        this.currentShift = this.nowShiftId;
        this.getMaxTime();
      }
    },
    onConfirm(value) {
      this.form.causeCode = value.value;
      this.$api.setCause.updateProjectCause(this.form).then((res) => {
        if (res.code == 200) {
          this.CausePopup = false;
          this.getOEEData();
          this.$toast.success(res.msg);
        }
      });
    },
    onCancel() {
      this.CausePopup = false;
    },
    //修改原因
    updateCause(row) {
      this.form.lampState = row.lampState;
      this.form.startTime = row.startTime;
      this.form.causeCode = row.causeCode * 1;
      this.form.projectId = row.projectId;
      this.form.triColorLampId = row.triColorLampId;
      this.CausePopup = true;
    },
    // pageChange(){

    // },
    // 获取分页oee列表
    getOEEData() {
      this.$api.triColorLamp.getLampPage(this.getForm).then((res) => {
        if (res.code == 200) {
          this.oeeData = res.data.records;
          this.getForm.total = res.data.total;
        }
      });
    },
    // 获取当天不分页oee列表
    getoeeList() {
      this.AbnormalData = [];
      this.getOEEData();
      this.getOeeEcharts();
      this.$api.setCause
        .getProjectCauseList(this.$route.query.projectId)
        .then((res) => {
          if (res.code == 200) {
            let arr = [];
            for (let key in res.data) {
              let obj = {
                value: key * 1,
                text: res.data[key],
              };
              arr.push(obj);
            }
            this.columns = arr;
          }
        });
      let data = {
        projectId: this.$route.query.projectId,
        date: this.getForm.date,
        type: this.currentShift ? 2 : 1,
        shiftId: this.currentShift,
      };
      this.$api.setCause.getProject(data).then((res) => {
        if (res.code == 200) {
          this.setCasuePie(res);
        }
      });
    },
    setCasuePie(res) {
      if (!res.data.proCause.length) {
        this.causePie.clear();
        return;
      }
      // let pieData = [];
      // res.data.forEach((item) => {
      //   pieData.push({ value: item.duration, name: item.causeName });
      // });
      //原因
      const allTime = res.data.proCause[0];
      let pieData = [];
      let pieItem = [];
      let pieItem1 = [];
      allTime.proCauseStateTime?.forEach((item) => {
        if (item.lampState == 1) {
          pieItem.push({ value: item.duration, name: "红灯" + item.causeName });
        } else if (item.lampState == 2) {
          pieItem1.push({
            value: item.duration,
            name: "黄灯" + item.causeName,
          });
        }
      });
      allTime.proStateTime?.forEach((item) => {
        if (item.lampState == 1) {
          pieData.push({
            value: item.duration,
            name: "红灯",
            itemStyle: { color: "red" },
          });
        } else {
          pieData.push({
            value: item.duration,
            name: "黄灯",
            itemStyle: { color: "#ffb933" },
          });
        }
      });
      //原因
      let pieDataT = [...pieItem, ...pieItem1];
      this.$nextTick(() => {
        this.setFrequency(pieData, pieDataT, "fourFrequency");
      });
    },
    getOeeEcharts() {
      let form = {
        projectId: this.$route.query.projectId,
        date: this.getForm.date,
        shiftId: this.currentShift,
      };
      this.$api.triColorLamp.getoeeList(form).then((res) => {
        if (res.code == 200) {
          this.oeeList = res.data.lamps;
          this.setHietory(res.data);
          this.getPieList();
        }
      });
    },
    setHietory(oeedata) {
      let data = oeedata.lamps;
      let bluedata = oeedata.blues;
      if (data.length == 0) {
        let chartDom = document.getElementById("oeeBody");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        return;
      }
      // 每天开始时间
      let startTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "00:00:01"
      );
      // 每天的结束时间
      let endTime = +new Date(
        data[data.length - 1].startTime.split(" ")[0] + " " + "23:59:59"
      );
      let historyData = [];
      for (let i = 0; i < bluedata.length; i++) {
        let date = +new Date(bluedata[i].startTime); //每条数据上传的时间
        let item = bluedata[i];
        historyData.push({
          name: "呼叫",
          value: [
            1,
            date,
            (date += item.duration * 1000),
            item.duration * 1000,
          ],
          itemStyle: {
            color: "#409eff",
          },
        });
      }
      for (let i = data.length - 1; i >= 0; i--) {
        let date = +new Date(data[i].startTime.replace(/-/g, "/")); //每条数据上传的时间
        let item = data[i];
        let time = +new Date(
          data[data.length - 1].startTime.replace(/-/g, "/")
        ); //第一次上传的时间
        if (i == data.length - 1 && startTime < time) {
          //如果每天的开始时间小于第一次上传的时间
          historyData.push({
            name: "",
            value: [
              "",
              startTime,
              (startTime += time - startTime),
              time - startTime,
            ],
            itemStyle: {
              color: "transparent",
            },
          });
        }
        let name, color;
        if (item.lampState == 1) {
          name = "红灯";
          color = "red";
        } else if (item.lampState == 2) {
          name = "黄灯";
          color = "rgb(255,185,51)";
        } else if (item.lampState == 3) {
          name = "绿灯";
          color = "rgb(96,185,51)";
        } else if (item.lampState == 0) {
          name = "灭灯";
          color = "rgba(0,0,0,0.1)";
        }
        historyData.push({
          name: name,
          value: [
            "",
            date,
            (date += item.duration * 1000),
            item.duration * 1000,
          ],
          itemStyle: {
            color: color,
          },
        });

        if (i == 0 && endTime > date + item.duration * 1000) {
          historyData.push({
            value: ["", date, (date += endTime - date), endTime - date],
            itemStyle: {
              color: "transparent",
            },
          });
        }
      }
      this.$nextTick(() => {
        if (this.type == "G") {
          this.setOee(historyData, "oeeBody", ["", "呼叫"], 1);
        } else {
          this.setOee(historyData, "oeeBody", [""], 0.4);
        }
      });
    },
    //获取饼图数据
    getPieList() {
      let red = 0;
      let green = 0;
      let yellow = 0;
      // let grey = 0;
      // let blue = 0;
      this.oeeList.forEach((i) => {
        switch (i.lampState) {
          // case 0:
          //   grey = grey + i.duration;
          //   break;
          case 1:
            red = red + i.duration;
            break;
          case 2:
            yellow = yellow + i.duration;
            break;
          case 3:
            green = green + i.duration;
            break;
          // case 4:
          //   blue = blue + i.duration;
          //   break;
          default:
            break;
        }
      });
      let pieData1 = [
        {
          value: (green / 3600).toFixed(2),
          name: "绿灯",
          itemStyle: { color: "#60b933" },
        },
        {
          value: (red / 3600).toFixed(2),
          name: "红灯",
          itemStyle: { color: "#ff0000" },
        },
        {
          value: (yellow / 3600).toFixed(2),
          name: "黄灯",
          itemStyle: { color: "#ffb933" },
        },
        // {
        //   value: (grey / 3600).toFixed(2),
        //   name: "灭灯",
        //   itemStyle: { color: "#808080" },
        // },
      ];
      // if (blue) {
      //   pieData1.push({
      //     value: (blue / 3600).toFixed(2),
      //     name: "蓝灯",
      //     itemStyle: { color: "#409eff" },
      //   });
      // }
      this.$nextTick(() => {
        if (this.type) {
          this.setfourPie(pieData1, "fourTime");
        } else {
          this.setPie(pieData1, "allTime");
        }
      });
    },
  },
  mounted() {
    let date = new Date();
    this.getForm.date =
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "-" +
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate());
    this.getShift();
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.box {
  width: 100%;
  height: 100%;
}
.oeeTitle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #666;
  font-weight: 650;
  font-size: 14px;
}
.header {
  height: 30%;
  display: flex;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: space-between;
  align-items: center;
}
::v-deep .header .el-input {
  width: 150px;
}
.haderTitle {
  font-size: 18px;
  display: inline-block;
  height: 28px;
  line-height: 28px;
}
.oeebody {
  height: 50%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 10px 28px;
}
.main {
  width: 100%;
  height: 20%;
  padding: 0 10px;
}
#oeeBody {
  width: 100%;
  height: 100%;
  border: 1px solid #dee1e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

#allTime {
  width: 100%;
  height: 100%;
  border: 1px solid #dee1e6;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}
.pieBox {
  width: 50%;
  height: 100%;
}
::v-deep .custom-image .van-empty__image {
  width: 60% !important;
  height: 90px !important;
}
</style>
